<template>
    <header-comp />
    <div class="container pb-5">
        <div class="mb-4">
            <news-block />
        </div>
        <div class="mb-4">
            <image-block />
        </div>
        <div>
            <domain-block />
        </div>
    </div>
</template>

<script>
import NewsBlock from '@/components/HomeComps/NewsBlock.vue'
import HeaderComp from '@/components/HeaderComp.vue'
import ImageBlock from '@/components/HomeComps/ImageBlock.vue'
import DomainBlock from '@/components/HomeComps/DomainBlock.vue'

export default {
  name: 'HomeView',
  components: { NewsBlock, HeaderComp, ImageBlock, DomainBlock }
}
</script>

<style>
    
</style>