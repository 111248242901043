<template>
    <div class="backdrop" @click.self="closeModal">
        <div class="block">
            <div class="main-heading">
                Images
            </div>

            <div class="image-list row">
                <div v-for="image in images" :key="image.id" class="col-md-6 image-item mb-4">
                    <div class="card">
                        <img :src="image.url" class="card-img-top" alt="Image">
                        <div class="card-body">
                            <div>ID: {{ image.id }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';

export default {
    name: 'Modal',
    data() {
        return {
            images: [],
            image: null,
        };
    },
    mounted() {
        this.fetchImages();
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async fetchImages() {
            const token = localStorage.getItem('token');
            if (token) {
                axios.get('/images', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.images = response.data;
                })
                .catch(error => {
                    // console.error('Ошибка при получении изображений:', error.response.data.message);
                });
            } else {
                // console.error('Токен не найден в localStorage');
            }
        }
    }
}
</script>

<style scoped>
    .block {
        width: 80%;
        padding: 50px;
        margin: 50px auto;
        background: white;
        border-radius: 10px;
        height: 90vh;
        overflow: hidden;
    }

    .backdrop {
        top: 0;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
    }

    .image-list {
        margin-top: 20px;
        max-height: calc(80vh - 60px);
        overflow-y: auto;
    }

    .image-item {
        margin-bottom: 10px;
    }

    .card {
        border: 1px solid #eee;
        border-radius: 10px;
    }

    .card-img-top {
        border-radius: 10px 10px 0 0;
        max-width: 100%;
        height: auto;
    }

    .card-body {
        padding: 10px;
    }
</style>

