import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ArticleView from '@/views/ArticleView.vue'
import LoginView from '@/views/Auth/LoginView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/article',
        name: 'article',
        component: ArticleView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/edit-article/:id',
        name: 'edit-article',
        component: ArticleView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
