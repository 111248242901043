<template>
    <router-view/>
</template>

<script>
import axios from '@/services/axios';

export default {
    name: 'App',
    data() {
        return {
            isAuth: null
        }
    },
    mounted() {
        this.getAuth();
    },
    methods: {
        getAuth() {
            this.isAuth = localStorage.getItem('isAuth')
        },
        logout() {
            const token = localStorage.getItem('token');
            if (token) {
                axios.post('/logout', {}, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Передаем токен в заголовке запроса
                    }
                })
                .then(response => {
                    console.log(response.data.message);
                    localStorage.removeItem('isAuth')
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'login' })
                })
                .catch(error => {
                    // console.error('Ошибка при выходе из системы:', error.response.data.message);
                });
            } else {
                // console.error('Токен не найден в localStorage');
            }
        }
    }
}
</script>

<style>
    #app {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        color: black;
    }

    header {
        padding: 30px;
        text-align: start;
    }

    header span {
        font-size: 24px;
        font-weight: 600;
    }

    .block {
        border: 2px solid black;
        border-radius: .75em;
        padding: 50px;

        font-size: 1.5em;
        font-weight: 500;
    }

    .block a {
        text-decoration: none;
        color: black;
    }

    .block form {
        font-size: 0.8em;
    }

    .news-block {
        padding: 30px;
        font-size: 1em;
        font-weight: 300;
    }

    .action-svg {
        cursor: pointer;
    }
</style>
