<template>
    <div class="auth-container mt-5">
        <div class="block">
            <span>Авторизация 🤖</span>

            <form @submit.prevent="login" class="mt-4">
                <div class="mb-4">
                    <input type="text" v-model="loginData.login" name="login" placeholder="Введите логин" required>
                </div>
                <div class="mb-4">
                    <input type="password" v-model="loginData.password" name="password" placeholder="Введите пароль" required>
                </div>

                <div>
                    <button type="submit" class="btn btn-dark btn-lg" style="width: 100%;">Войти</button>
                </div>
            </form>

            <p v-if="errorMessage">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';

export default {
    name: 'LoginView',
    data() {
        return {
            loginData: {
                login: '',
                password: ''
            },
            errorMessage: ''
        }
    },
    methods: {
        login() {
            axios.post('/login', this.loginData)
                .then(response => {
                    const token = response.data.token
                    const user = response.data.user

                    localStorage.setItem('isAuth', true)
                    localStorage.setItem('token', token)
                    localStorage.setItem('userID', user.id)
                    localStorage.setItem('user', user.login)

                    // console.log(response)
                    this.$router.push({ name: 'home' })
                })
                .catch(error => {
                    this.errorMessage = error;
                });
        }
    }
}
</script>

<style scoped>
    .auth-container {
        width: 30%;
        margin: 0 auto;
    }
</style>