<template>
    <div class="block">
        <div class="d-flex justify-content-between">
            <span>Домены 🔧</span>
            <!-- <button class="btn btn-dark btn-lg">
                Посмотреть
            </button> -->
        </div>

        <form @submit.prevent="addDomain" class="mt-4">
            <div class="row mb-3">
                <div class="col">
                    <input type="text" v-model="newDomain.domain" name="domain" placeholder="Домен" required>
                </div>
                <div class="col">
                    <input type="text" v-model="newDomain.name" name="name" placeholder="Название" required>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <input type="text" v-model="newDomain.ns1" name="ns1" placeholder="NS 1" required>
                </div>
                <div class="col">
                    <input type="text" v-model="newDomain.ns2" name="ns2" placeholder="NS 2" required>
                </div>
                <div class="col">
                    <input type="text" v-model="newDomain.site" name="site" placeholder="Сайт" required>
                </div>
                <div class="col">
                    <select v-model="newDomain.user_id" name="user_id" required>
                        <option v-for="user in users" :key="user.id" :value="user.id">{{ user.login }}</option>
                    </select>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-dark btn-lg" style="width: 100%;">Добавить</button>
            </div>
        </form>

        <div class="mt-4">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Домен</th>
                        <th scope="col">Название</th>
                        <th scope="col">Сайт</th>
                        <!-- <th scope="col">NS 1</th>
                        <th scope="col">NS 2</th> -->
                        <th scope="col">Пользователь</th>
                        <th scope="col">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="domain in domains" :key="domain.id">
                        <th scope="row">{{ domain.id }}</th>
                        <td>{{ domain.domain }}</td>
                        <td>{{ domain.name }}</td>
                        <td>{{ domain.site }}</td>
                        <!-- <td>{{ domain.ns1 }}</td>
                        <td>{{ domain.ns2 }}</td> -->
                        <td>{{ domain.user_id }}</td>
                        <td>
                            <div class="d-flex">
                                <div class="action-svg" @click.prevent="deleteDomain(domain.id)">
                                    <svg viewBox="0 0 24 24" height="24" width="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M14 10V17M10 10V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios'

export default {
    name: 'DomainBlock',
    components: {  },
    data() {
        return {
            users: null,
            domains: null,

            newDomain: {
                domain: null,
                name: null,
                ns1: null,
                ns2: null,
                site: null,
                user_id: null
            }
        };
    },
    mounted() {
        this.getUsers()
        this.getDomains()
    },
    methods: {
        async getUsers() {
            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')
            if (token) {
                axios.post('/get-all-users', { login: user }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.users = response.data.users;
                })
                .catch(error => {
                    // console.error('Ошибка при получении изображений:', error.response.data.message);
                });
            } else {
                // console.error('Токен не найден в localStorage');
            }
        },
        async getDomains() {
            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')
            if (token) {
                axios.post('/get-all-domains', { login: user }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.domains = response.data.domains;
                })
                .catch(error => {
                    // console.error('Ошибка при получении изображений:', error.response.data.message);
                });
            } else {
                // console.error('Токен не найден в localStorage');
            }
        },
        async addDomain() {
            const domainData = {
                domain: this.newDomain.domain,
                name: this.newDomain.name,
                ns1: this.newDomain.ns1,
                ns2: this.newDomain.ns2,
                site: this.newDomain.site,
                user_id: this.newDomain.user_id
            };

            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')

            if (token) {
                axios.post('/add-domain', { domainData: domainData, login: user }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    alert('Домен добавлен.')
                    this.getDomains()
                    this.clearDomainForm()
                })
                .catch(error => {
                    // console.error('Ошибка при получении изображений:', error.response.data.message);
                })
            } else {
                // console.error('Токен не найден в localStorage');
            }
        },
        clearDomainForm() {
            this.newDomain.domain = '',
            this.newDomain.name = '',
            this.newDomain.ns1 = '',
            this.newDomain.ns2 = '',
            this.newDomain.site = '',
            this.newDomain.user_id = ''
        },
        async deleteDomain(domainId) {
            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')

            if (token) {
                axios.post('/delete-domain', { domain_id: domainId, login: user }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    alert('Домен удален.')
                    this.getDomains()
                })
                .catch(error => {
                    // console.error('Ошибка при удалении домена:', error.response.data.message);
                })
            } else {
                // console.error('Токен не найден в localStorage');
            }
        }
    }
}
</script>

<style>
    table {
        font-size: 0.7em;
    }
</style>