<template>
    <header>
        <div class="container">
            <div class="d-flex justify-content-between">
                <span>FCN</span>
                <button class="btn btn-dark btn-lg" @click.prevent="logout">Выйти</button>
            </div>
        </div>
    </header>
</template>

<script>
import axios from '@/services/axios';

export default {
    name: 'HeaderComp',
    data() {
        return {
            isAuth: null,
        }
    },
    mounted() {
        this.getAuth();
    },
    methods: {
        getAuth() {
            this.isAuth = localStorage.getItem('isAuth')
        },
        logout() {
            const token = localStorage.getItem('token');
            if (token) {
                axios.post('/logout', {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    console.log(response.data.message)
                    localStorage.removeItem('isAuth')
                    localStorage.removeItem('token')
                    localStorage.removeItem('userID')
                    localStorage.removeItem('user')
                    this.$router.push({ name: 'login' })
                })
                .catch(error => {
                    // console.error('Ошибка при выходе из системы:', error.response.data.message);
                });
            } else {
                // console.error('Токен не найден в localStorage');
            }
        }
    }
}
</script>

<style scoped>

</style>