<template>
    <div v-if="showBrowse">
        <modal-images @close="toggleBrowseImage" />
    </div>

    <header-comp />

    <div class="container pb-5">
        <div class="block">
            <div class="d-flex justify-content-between">
                <span>Новая статья ✏️</span>
                <router-link :to="{ name: 'home' }">
                    <button class="btn btn-dark btn-lg">
                        Вернуться
                    </button>
                </router-link>
            </div>

            <form @submit.prevent="submitArticle" class="mt-4">
                <div class="row gx-5 mb-4">
                    <div class="col">
                        <label for="title">Title</label>
                        <input type="text" v-model="title" name="title" placeholder="Enter title" required>
                    </div>
                    <div class="col">
                        <label for="author">Author</label>
                        <input type="text" v-model="author" name="author" placeholder="Enter author" required>
                    </div>
                </div>
                <div class="row gx-5 mb-4">
                    <div class="col">
                        <label for="date">Publish Date</label>
                        <input type="date" v-model="date" name="date" placeholder="Enter date" required>
                    </div>
                    <div class="col">
                        <label for="keyword">Keyword</label>
                        <input type="text" v-model="keyword" name="keyword" placeholder="Enter keyword" required>
                    </div>
                </div>
                <div class="row gx-5 mb-4">
                    <div class="col">
                        <label for="image">Image ID</label>
                        <div class="d-flex">
                            <input type="text" v-model="imageId" name="image" placeholder="Enter image ID" required>
                            <button type="button" class="btn btn-dark btn-lg ms-4" @click="toggleBrowseImage">Обзор</button>
                        </div>
                    </div>
                </div>
                <div class="row gx-5 mb-4">
                    <div class="col">
                        <label for="message" class="form-heading">Message</label>
                        <div v-if="!showBrowse">
                            <editor
                                v-model="message"
                                api-key="vfb7g83565nsxajpkg6pfnsf9g87519d4qolayd7b9mt07lc"
                                :init="{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist checklist outdent indent | removeformat | a11ycheck code table help'
                                }"
                                initial-value="Here is article content"
                            />
                        </div>
                    </div>
                </div>
                <div class="row gx-5 mb-4">
                    <div class="col">
                        <label for="link">Link</label>
                        <input type="text" v-model="link" name="link" placeholder="Enter link" required>
                    </div>
                    <div class="col">
                        <label for="site">Site</label>
                        <input type="text" v-model="site" name="site" placeholder="Enter site" required>
                    </div>
                </div>
                <div class="row gx-5 mb-4">
                    <div class="col">
                        <label for="isWork">Is Work</label>
                        <select v-model="isWork" name="isWork" required>
                            <option :value="1" selected>Yes</option>
                            <option :value="0">No</option>
                        </select>
                    </div>
                </div>
                <div>
                    <button type="submit" class="btn btn-dark btn-lg" style="width: 100%;">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import ModalImages from '@/components/ModalImages.vue';
import HeaderComp from '@/components/HeaderComp.vue';
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'ArticleForm',
    components: { ModalImages, HeaderComp, 'editor': Editor },
    data() {
        return {
            id: null,
            title: '',
            author: '',
            date: '',
            keyword: '',
            imageId: '',
            message: '',
            link: '',
            site: '',
            isWork: 1,
            showBrowse: false,
        };
    },
    created() {
        const articleId = this.$route.params.id;
        if (articleId) {
            this.id = articleId;
            this.loadArticle(articleId);
        }
    },
    methods: {
        toggleBrowseImage() {
            this.showBrowse = !this.showBrowse;
        },
        async loadArticle(articleId) {
            const token = localStorage.getItem('token');
            const user = localStorage.getItem('user');

            if (token) {
                try {
                    const response = await axios.post('/get-article-id', { article_id: articleId, login: user }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const article = response.data;
                    this.title = article.title;
                    this.author = article.author;
                    this.date = article.publication_date;
                    this.keyword = article.keyword;
                    this.imageId = article.image_id;
                    this.message = article.content;
                    this.link = article.link;
                    this.site = article.site;
                    this.isWork = article.isWork;
                } catch (error) {
                    console.error(error);
                    // handle error
                }
            } else {
                console.error('Missing token');
                // handle missing token
            }
        },
        async submitArticle() {
            const articleData = {
                id: this.id,
                title: this.title,
                author: this.author,
                publication_date: this.date,
                keyword: this.keyword,
                image_id: this.imageId,
                content: this.message,
                link: this.link,
                site: this.site,
                isWork: this.isWork
            };

            const token = localStorage.getItem('token');
            const user = localStorage.getItem('user');
            const url = '/store-article';

            if (token) {
                try {
                    await axios.post(url, { articleData: articleData, login: user }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    alert(this.id ? 'Статья обновлена.' : 'Статья добавлена.');
                    this.clearForm();
                    this.$router.push({ name: 'home' });
                } catch (error) {
                    console.error(error);
                    // handle error
                }
            } else {
                console.error('Missing token');
                // handle missing token
            }
        },
        clearForm() {
            this.id = null;
            this.title = '';
            this.author = '';
            this.date = '';
            this.keyword = '';
            this.imageId = '';
            this.message = '';
            this.link = '';
            this.site = '';
            this.isWork = 1;
        }
    }
};
</script>

<style>
    form input, form select, form textarea {
        border: 0;
        padding: 10px 20px;
        border: 2px solid #eee;
        border-radius: .75em;
        width: 100%;
    }

    form select {
        display: block; 
        line-height: 1.3; 
        padding: 10px 20px;
        width: 100%;
        border-radius: 0;
        box-sizing: border-box;
        border: 2px solid #eee;
        border-radius: .75em;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    form input:focus, form select:focus, form textarea:focus {
        border: 2px solid #0f0f0f;
        border-radius: .75em;
        outline: none;
    }

    form label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.8em;
    }

    .text-editor {
        border: 0;
        padding: 10px 20px;
        border: 2px solid #eee;
        width: 100%;
    }

    .text-editor:focus {
        border: 2px solid #0f0f0f;
        border-radius: .75em;
        outline: none;
    }
</style>
