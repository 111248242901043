<template>
    <div class="block">
        <div class="d-flex justify-content-between">
            <span>Картинки 🖼️</span>
            <!-- <button class="btn btn-dark btn-lg">
                Посмотреть
            </button> -->
        </div>

        <form @submit.prevent="uploadImage" class="mt-4">
            <div class="d-flex">
                <input type="file" name="image" accept="image/*" ref="fileInput" placeholder="Выберите картинку" required @change="onFileChange">
                <button type="submit" class="btn btn-dark btn-lg ms-3" style="width: 20%;">Загрузить</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from '@/services/axios'

export default {
    name: 'ImageBlock',
    components: {  },
    data() {
        return {
            image: null
        };
    },
    mounted() {
        
    },
    methods: {
        onFileChange(e) {
            console.log('File selected:', e.target.files[0]);
            this.image = e.target.files[0];
        },
        async uploadImage() {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    if (!this.image) {
                        alert('Please select an image');
                        return;
                    }

                    console.log('Uploading image:', this.image.name);
                    
                    let formData = new FormData();
                    formData.append('image', this.image);

                    const response = await axios.post('/upload-image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    console.log('Upload response:', response);

                    alert('Image uploaded successfully!');
                    this.image = null;
                    this.$refs.fileInput.value = null;
                } catch (error) {
                    console.error('Error uploading image:', error);
                    alert('Failed to upload image');
                }
            } else {
                console.error('Token not found in localStorage');
            }
        }
    }
}
</script>

<style>

</style>