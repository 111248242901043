<template>
    <div class="block">
        <div class="d-flex justify-content-between">
            <span>Новости 📰</span>
            <router-link :to="{ name: 'article' }">
                <button class="btn btn-dark btn-lg">
                    Добавить новую
                </button>
            </router-link>
        </div>

        <div class="news-block mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Заголовок</th>
                        <th scope="col">Автор</th>
                        <th scope="col">Дата публикации</th>
                        <th scope="col">Ключевое слово</th>
                        <th scope="col">Сайт</th>
                        <th scope="col">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="article in articles" :key="article.id">
                        <th scope="row">{{ article.id }}</th>
                        <td>{{ article.title }}</td>
                        <td>{{ article.author }}</td>
                        <td>{{ article.publication_date }}</td>
                        <td>{{ article.keyword }}</td>
                        <td>{{ article.site }}</td>
                        <td>
                            <div class="d-flex">
                                <div class="action-svg me-2" @click.prevent="editArticle(article.id)">
                                    <svg viewBox="0 0 24 24" height="24" width="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 14H12.5M7 10H17M10 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V8.5M14 20L16.025 19.595C16.2015 19.5597 16.2898 19.542 16.3721 19.5097C16.4452 19.4811 16.5147 19.4439 16.579 19.399C16.6516 19.3484 16.7152 19.2848 16.8426 19.1574L21 15C21.5523 14.4477 21.5523 13.5523 21 13C20.4477 12.4477 19.5523 12.4477 19 13L14.8426 17.1574C14.7152 17.2848 14.6516 17.3484 14.601 17.421C14.5561 17.4853 14.5189 17.5548 14.4903 17.6279C14.458 17.7102 14.4403 17.7985 14.405 17.975L14 20Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>                                </div>
                                <div class="action-svg" @click.prevent="deleteArticle(article.id)">
                                    <svg viewBox="0 0 24 24" height="24" width="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M14 10V17M10 10V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios'

export default {
    name: 'NewsBlock',
    data() {
        return {
            articles: null
        }
    },
    mounted() {
        this.getArticles()
    },
    methods: {
        async getArticles() {
            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')
            if (token) {
                axios.post('/get-all-news', { login: user }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.articles = response.data;
                })
                .catch(error => {
                    // console.error('Ошибка при получении изображений:', error.response.data.message);
                });
            } else {
                // console.error('Токен не найден в localStorage');
            }
        },
        async deleteArticle(articleId) {
            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')

            if (token) {
                axios.post('/delete-article', { article_id: articleId, login: user }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    alert('Статья удалена.')
                    this.getArticles()
                })
                .catch(error => {
                    // console.error('Ошибка при удалении домена:', error.response.data.message);
                })
            } else {
                // console.error('Токен не найден в localStorage');
            }
        },
        editArticle(articleId) {
            this.$router.push({ name: 'edit-article', params: { id: articleId } });
        }
    }
}
</script>

<style>

</style>